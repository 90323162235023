






















import {
  Component,
  Vue,
} from 'vue-property-decorator';
import { Core } from '@/store/core'
import { Auth } from '@/store/auth'
import { User } from '@/store/user'

import CityDialog from '@/components/Dialog/City.vue'
import { App } from "@/store/app";

@Component({
  components: { CityDialog },
  computed: {}
})

export default class Home extends Vue {
  private form: any = {
    "username": "root",
    "password": "Pautn1611"
  }


  private async created() {

  }

}
